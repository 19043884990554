import React from "react"
import { Link } from "gatsby"
import Section from "../../../components/section/Section"

interface SitemapMenuItem {
  item: string
  link: string
  foreignLink: boolean
}

interface SitemapMenu {
  sitemapMenuHeading: string
  sitemapMenuItems: SitemapMenuItem[]
  link: string
}

const SitemapSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { heading, sitemapMenu } = data.data.content.SitemapSectionFirstConfig
  const { locale } = data.data.pageContext

  return (
    <Section className={className}>
      <h2 className="sova-h2 sova-h2_sitemap">{heading}</h2>
      <div className="sova-section-container sova-section-container_sitemap-1">
        {sitemapMenu.map(({ sitemapMenuHeading, sitemapMenuItems, link }: SitemapMenu, key: number) => (
          <div key={key} className={`sova-section-child-container sova-section-child-container_row sova-section-child-container_sitemap sova-section-child-container_sitemap-${key}`}>
            <div className={`sova-section-sitemap-decorative-element sova-section-sitemap-decorative-element-${key}`}></div>
            <div className="sova-section-container sova-section-container_sitemap-menu-1">
              {link ? (
                <Link to={`${link === "/mainDocPage" ? link : locale + link}`} className="sova-h5">
                  {sitemapMenuHeading}
                </Link>
              ) : (
                <h5 className="sova-h5">{sitemapMenuHeading}</h5>
              )}
              {sitemapMenuItems && (
                <ul className="sova-section-column sova-section-container_sitemap-menuItems-1">
                  {sitemapMenuItems.map(({ item, link, foreignLink }, key) =>
                    foreignLink ? (
                      <a href={link} key={key} target="blank" className="sova-section-child sova-section-child_sitemap-1-menuItem sova-p-tiny">
                        {item}
                      </a>
                    ) : (
                      <Link key={key} to={`${link === "/mainDocPage" ? link : locale + link}`} className="sova-section-child sova-section-child_sitemap-1-menuItem sova-p-tiny">
                        {item}
                      </Link>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

SitemapSectionFirst.displayName = "SitemapSectionFirst"

export default SitemapSectionFirst
