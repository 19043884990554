import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"
import SitemapSections from "../pagesStructures/sitemap/sitemapSections"

const SiteMap = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiSitemap.content.find((el: any) => {
    return el.language === pageContext.lang
  })
  return (
    <Layout bgCustomClass="sitemap">
      <SEO title={pageContent.content.title} />
      <SitemapSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default SiteMap
export const query = graphql`
  query SiteMap($url: String) {
    strapiSitemap(url: { eq: $url }) {
      content {
        language
        content {
          title
          SitemapSectionFirstConfig {
            heading
            sitemapMenu {
              link
              sitemapMenuHeading
              sitemapMenuItems {
                item
                link
                foreignLink
              }
            }
          }
        }
      }
    }
  }
`
