import React from "react"

import SitemapSectionFirst from "./sitemapSections/sitemapSectionFirst"

const sections = [{ component: (key: number, className: string, data: any) => <SitemapSectionFirst data={data} className={className} key={key} /> }]

const SitemapSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-sitemap  sova-section-sitemap_${i}`, data))}</>
}

SitemapSections.displayName = "SitemapSections"

export default SitemapSections
